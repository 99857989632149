import requests from '@/api/BaseAliOss'
import _ from 'lodash'

let OSS = require('ali-oss')

export default function ossProtype(params) {
  return requests.getOssTokenNew(params).then(res => {
    let _data = res.data.stsCredentials

    if (_data.accessKeyId) {
      const accessKeyId = _.get(_data, 'accessKeyId')
      const accessKeySecret = _.get(_data, 'accessKeySecret')
      const stsToken = _.get(_data, 'stsToken')

      let ossObj = new OSS({
        accessKeyId,
        accessKeySecret,
        stsToken,
        region: _.get(_data, 'region'),
        bucket: _.get(_data, 'bucket'),
        timeout: 60000 * 5,
      })
      
      return {
        Client: ossObj,
        options: {callback: _.get(res.data, 'callback')},
        url: _.get(res.data, 'objectName'),
        cdnDomain: _.get(res.data, 'cdnDomain'),
      }
    } else {
      console.error(res)
      return
    }
  })

}
