import ossProtype from './oss.js';
import _ from 'lodash';
import recordUpload from '@/utils/recordUpload';
import _base_https from '@/api/base_api';

export default {
  data() {
    return {
      progress: 0,
      is_progress: false,
      _oss: {
        status: '',
        client: null,
      },
      uploader: null,
      uploader_list: [null],
      video_id: 0,
      cdnDomain: 'https://vod.afanticar.com',
      requestResult: {},
      abortCheckpoint: {},
    };
  },
  created() {
    //isOssAuth判断是否需要初始化_initOSSAuth
    // if(this.isOssAuth) {
    //   this._initOSSAuth()
    // }
    // this._initOSSAuth()
  },
  methods: {
    _initOSSAuth(file, onSuccess, isMultiple) {
      // isMultiple - 多张图片上传 不校验status状态
      console.log(this._oss);
      if (this._oss && !isMultiple) {
        if (this._oss.status === 'success' || this._oss.status === 'pedding') {
          return;
        }
      }

      this._oss = {
        status: 'pedding',
        client: null,
      };

      const params = file.params_data?.appId ? file.params_data : this.params_data;

     return ossProtype(params)
        .then((res) => {
          if (res) {
            this._oss = {
              status: 'success',
              client: res.Client,
            };
            this.requestResult = res;
            this.cdnDomain = res.cdnDomain;

            this._oss.client
              .put(this.requestResult.url, file, this.requestResult.options)
              .then((data) => {
                // onSuccess(data, data.url);
                console.log({ data }, { res }, `${this.cdnDomain}/${data.name}`);
                onSuccess(data, `${this.cdnDomain}/${data.name}`);
              })
              .catch((error) => {
                const uid = file.uid;
                this.file_list.forEach((data) => {
                  if (data.uid === uid) {
                    data.status = 'error';
                  }
                });
                console.error(error);
                this.is_default_upload = false;
                this.is_progress = false;
                // this._initOSSAuth();
                // this.file_list = []
                this._oss = {
                  status: 'failure',
                  client: null,
                };
                this.$message.error(`请重新上传或者刷新页面`);
              });

            this._oss = {
              status: '',
              client: null,
            };
          } else {
            this._oss = {
              status: 'failure',
              client: null,
            };
            this.$message.error(res);
          }
        })
        .catch((err) => {
          this._oss = {
            status: 'failure',
            client: null,
          };
          this.$message.error('获取oss客户端失败');
          console.error(err);
        });
    },
    async multipartUpload(file, onSuccess, file_index) {
      this.abortCheckpoint = {};
      try {
        const this_ = this;
        const time = this.$moment()._d;
        const date = `${time.getFullYear()}-${time.getMonth() + 1}-${time.getDate()}`;
        const url = this.custom_request_type + '/' + date + '/' + file.name;
        const [record, removeRecord] = recordUpload(url);

        !file_index ? (file_index = 0) : '';
        const params = file.params_data?.appId ? file.params_data : this.params_data;

        ossProtype(params)
          .then(async (res) => {
            if (res) {
              this._oss = {
                status: 'success',
                client: res.Client,
              };

              this.cdnDomain = res.cdnDomain;

              const data = await this._oss.client.multipartUpload(res.url, file, {
                progress: function (p, checkpoint) {
                  if (file_index >= this_.file_list.length) {
                    return;
                  }
                  let fileObj = this_.file_list[file_index];
                  fileObj.percent = p * 100;
                  fileObj.status = 'uploading';

                  //显示百分比进度
                  this_.progress = parseInt(p * 100);
                  if (p == 1) {
                    this_.is_progress = false;
                  } else {
                    !this_.is_progress ? (this_.is_progress = true) : '';
                  }
                  // 为中断点赋值。
                  this_.abortCheckpoint = checkpoint;
                  record(checkpoint);
                },
              });

              removeRecord();
              let dataUrl = _.get(data, 'res.requestUrls[0]');
              dataUrl = dataUrl.split('?')[0];
              data.url = dataUrl;
              data.cdnUrl = `${this.cdnDomain}/${data.name}`;
              this_.progress = 0;
              this._oss = {
                status: '',
                client: null,
              };
              onSuccess(data, `${this.cdnDomain}/${data.name}`);
            } else {
              this._oss = {
                status: 'failure',
                client: null,
              };
              this.$message.error(res);
            }
          })
          .catch((err) => {
            this._oss = {
              status: 'failure',
              client: null,
            };
            if (err.name != 'cancel') this.$message.error('获取oss客户端失败');
            console.error(err);
          });
      } catch (err) {
        console.error(err);
        this.file_list = [];
        this._initOSSAuth(file, onSuccess);
        this.$message.error(`请重新上传或者刷新页面`);
      }
    },
    cancelCurrentUpload() {
      if (this._oss.client && this.abortCheckpoint.name) {
        this._oss.client.abortMultipartUpload(this.abortCheckpoint.name, this.abortCheckpoint.uploadId);
        this.abortCheckpoint = {};
      }
    },
    async normalUploadFile(file, onSuccess, isMultiple) {
      try {
      await  this._initOSSAuth(file, onSuccess, isMultiple);
      } catch (err) {
        console.error(err);
        this._oss = {
          status: '',
          client: null,
        };
        this.file_list = [];
        this._initOSSAuth(file, onSuccess);
        this.$message.error(`请重新上传或者刷新页面`);
      }
    },
    async vodUpload(file, onSuccess, onProgress, i = 0) {
      this.uploader_list[i] = null;
      await this.baseVodUpload(i, file, onSuccess, onProgress);
    },
    baseVodUpload(i, file, onSuccess, onProgress) {
      let userData = '{"Vod":{}}';
      if (this.uploader_list[i]) {
        this.uploader_list[i].stopUpload();
      }
      this.uploader_list[i] = this.createUploader(i, file, onSuccess, onProgress);
      this.uploader_list[i].addFile(file, null, null, null, userData);
      this.uploader_list[i].startUpload();
    },
    createUploader(i, file, onSuccess, onProgress) {
      const self = this;
      let uploader = new AliyunUpload.Vod({
        timeout: self.uploader_info || 60000,
        partSize: self.partSize || 1048576,
        parallel: self.parallel || 5,
        retryCount: self.retryCount || 3,
        retryDuration: self.retryDuration || 2,
        userId: self.userId || '123',

        // 开始上传
        onUploadstarted: function (uploadInfo) {
          console.log('uploadInfo', uploadInfo);
          if (uploadInfo.videoId) {
            let params = {
              videoId: uploadInfo.videoId,
            };
            _base_https.refreshVod(params).then((res) => {
              console.log('重新上传');
              if (res.code === 200) {
                let uploadAuth = res.data.uploadAuth;
                let uploadAddress = res.data.uploadAddress;
                uploader.setUploadAuthAndAddress(uploadInfo, uploadAuth, uploadAddress);
                uploader.addFile();
                uploader.listFiles();
              } else {
                self.$message.error('刷新凭证失败');
              }
            });
          } else {
            _base_https[self.isNewCreateVod ? 'createVodNew' : 'createVod'](self.params_data).then((res) => {
              if (res.code === 200) {
                let uploadAuth = res.data.uploadAuth;
                let uploadAddress = res.data.uploadAddress;
                let videoId = res.data.videoId;
                self.video_id = videoId;
                self.cdnDomain = res.data.cdnDomain;

                console.log('cdnDomain', self.cdnDomain, res.data.cdnDomain);
                uploader.setUploadAuthAndAddress(uploadInfo, uploadAuth, uploadAddress, videoId);
              } else {
                self.$message.error('获取凭证失败');
              }
            });
          }
        },
        // 文件上传成功
        onUploadSucceed: function (uploadInfo) {
          //获取cdn加速的域名
          let domain = 'https://vod.afanticar.com';
          if (self.isNewCreateVod) {
            domain = self.cdnDomain;
          } else {
            _base_https.getUrlAuthKey().then((res) => {
              if (res.code === 200) {
                domain = res.data.cdnDomainName;
              } else {
                console.error('无法获取url domain');
              }
            });
          }

          let url = `${domain}/${uploadInfo.object}`; //cdn加速的域名

          console.log('uploadInfo', { url }, domain, uploadInfo);
          onSuccess(uploadInfo, url);
        },
        // 文件上传失败
        onUploadFailed: function (uploadInfo, code, message) {
          self.$message.error('文件上传失败');
          self.file_list = [];
        },
        // 文件上传进度，单位：字节, 可以在这个函数中拿到上传进度并显示在页面上
        onUploadProgress: function (uploadInfo, totalSize, progress) {
          // console.log('onUploadProgress:file:' + uploadInfo, uploadInfo.file.name + ', fileSize:' + totalSize + ', percent:' + Math.ceil(progress * 100) + '%')
          let progressPercent = Math.ceil(progress * 100);
          self.progress = progressPercent;
          onProgress({ percent: progressPercent });

          if (progressPercent == 100) {
            self.is_progress = false;
          } else {
            !self.is_progress ? (self.is_progress = true) : '';
          }
        },
        // 上传凭证超时
        onUploadTokenExpired: function (uploadInfo) {
          let params = {
            videoId: self.video_id,
          };
          _base_https.refreshVod(params).then((res) => {
            if (res.code === 200) {
              let uploadAuth = res.data.UploadAuth;
              uploader.resumeUploadWithAuth(uploadAuth);
            } else {
              self.$message.error('刷新凭证失败');
            }
          });
        },
        // 全部文件上传结束
        onUploadEnd: function (uploadInfo) {
          console.log('onUploadEnd: uploaded all the files');
        },
      });

      console.log(uploader);
      return uploader;
    },
  },
};
