<template>
  <a-modal
    title="任务详情"
    v-model="showUpdateModal"
    width="700px"
    centered
    v-bind="$attrs"
    v-on="$listeners"
    @close="handleModalClose"
    @cancel="handleModalClose"
  >
    <a-spin :spinning="loading">
      <a-tabs v-model="activeKey">
        <a-tab-pane key="1" tab="任务信息">
          <div class="title-row">任务规则</div>
          <div class="detail-row">
            <span class="detail-left">任务名称</span>
            <span class="detail-right">{{ detailInfo.taskName }}</span>
          </div>
          <div class="detail-row">
            <span class="detail-left">任务类型</span>
            <span class="detail-right">{{ detailInfo.taskTypeDesc }}</span>
          </div>
          <div class="detail-row">
            <span class="detail-left">拍摄数量</span>
            <span class="detail-right">{{ detailInfo.num }}</span>
          </div>
          <div class="detail-row">
            <span class="detail-left">任务描述</span>
            <span class="detail-right">{{ detailInfo.description }}</span>
          </div>
          <div class="detail-row">
            <span class="detail-left">时间</span>
            <span class="detail-right">{{
              `${detailInfo.startTime ? detailInfo.startTime.split(' ')[0] : '-'} 至 ${
                detailInfo.endTime ? detailInfo.endTime.split(' ')[0] : '-'
              }` || '-'
            }}</span>
          </div>
          <a-divider />
          <div class="title-row">任务规则</div>
          <div class="detail-row">
            <span class="detail-left">品牌车系</span>
            <span class="detail-right">{{ detailInfo.principalName }} - {{ detailInfo.carSeriesName }}</span>
          </div>
          <div class="detail-row">
            <span class="detail-left">门店统计</span>
            <span class="detail-right"
              >{{ detailInfo.memberIdList.length }} 家门店
              <a-button style="margin-left: 5px" type="link" size="small" @click="activeKey = '2'"
                >查看名单</a-button
              ></span
            >
          </div>
          <a-divider />
          <div class="title-row">参考示例</div>
          <div class="img-list">
            <img
              v-for="(v, i) in detailInfo.referMaterials"
              :key="i"
              :src="v"
              alt="示例图"
              referrerpolicy="no-referrer"
              @click="handlePreview(detailInfo.referMaterials, i)"
            />
          </div>
        </a-tab-pane>
        <a-tab-pane key="2" tab="达成情况">
          <div class="title-row">未达成门店</div>
          <div class="table-line table-title-line">
            <span class="table-column">门店名称</span>
            <span class="table-column">拍摄图片数</span>
            <span class="table-column">合格图片数</span>
          </div>
          <div class="table-line" v-for="(v, i) in unsuccessList" :key="i">
            <span class="table-column">{{ v.memberName }}</span>
            <span class="table-column">{{ v.totalCount }}</span>
            <span class="table-column">{{ v.passedCount }}</span>
          </div>
          <a-divider />
          <div class="title-row">已达成门店</div>
          <div class="table-line table-title-line">
            <span class="table-column">门店名称</span>
            <span class="table-column">拍摄图片数</span>
            <span class="table-column">合格图片数</span>
          </div>
          <div class="table-line" v-for="v in successList" :key="v.name">
            <span class="table-column">{{ v.memberName }}</span>
            <span class="table-column">{{ v.totalCount }}</span>
            <span class="table-column">{{ v.passedCount }}</span>
          </div>
        </a-tab-pane>
      </a-tabs>
    </a-spin>
    <div slot="footer">
      <a-button type="primary" @click="handleModalClose">关闭</a-button>
    </div>
  </a-modal>
</template>

<script>
import { api as viewerApi } from 'v-viewer';
import api from '@/api/xhsAgencyApi';

export default {
  name: '',
  props: {},
  components: {},
  data() {
    return {
      activeKey: '1',
      showUpdateModal: false,
      loading: false,
      detailInfo: {
        taskType: { desc: '-' },
        memberIdList: [],
        referMaterials: [],
      },
      unsuccessList: [],
      successList: [],
    };
  },
  methods: {
    handleOpen(id) {
      this.showUpdateModal = true;
      this.getDetailInfo(id);
      this.getAchieveInfo(id);
    },
    async getDetailInfo(id) {
      this.loading = true;
      const { data, message, code } = await api.getTaskDetail(id).finally(() => (this.loading = false));
      if (code == 200) {
        this.detailInfo = { ...data };
      } else {
        this.$message.error(message);
      }
    },
    async getAchieveInfo(id) {
      this.loading = true;
      const { data, message, code } = await api.getTaskAchievement(id).finally(() => (this.loading = false));
      if (code == 200) {
        this.unsuccessList = data.false;
        this.successList = data.true;
      } else {
        this.$message.error(message);
      }
    },
    handleModalClose() {
      this.showUpdateModal = false;
      this.detailInfo = this.$options.data().detailInfo;
      this.activeKey = '1';
    },
    handlePreview(images, initialViewIndex) {
      const $viewer = this.$viewerApi({
        options: {
          toolbar: true,
          initialViewIndex,
        },
        images,
      });
    },
  },
};
</script>

<style lang="less" scoped>
.title-row {
  margin-bottom: 6px;
  font-weight: bold;
}

.detail-row {
  display: flex;
  margin-bottom: 10px;

  .detail-left {
    flex-shrink: 0;
    margin-right: 20px;
    width: 75px;
    color: #adadad;
  }
}

.img-list {
  display: flex;
  overflow-x: auto;

  img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    margin-right: 10px;
    cursor: pointer;
    background-color: #cccccc2a;
  }
}

.table-line {
  display: flex;
  height: 30px;
  line-height: 30px;
  &.table-title-line {
    background-color: #f2f2f2;
  }

  .table-column {
    flex: 1;
  }
}
</style>
