export const columns = [
  {
    align: 'center',
    title: '任务类型',
    width: 90,
    dataIndex: 'taskTypeDesc',
    // customRender: (text) => {
    //   return text == 'PHOTOS' ? '图片' : '视频' || '-';
    // },
  },
  {
    align: 'center',
    title: '任务名称',
    dataIndex: 'taskName',
    customRender: (text) => {
      return text || '-';
    },
  },
  {
    align: 'center',
    title: '任务周期',
    width: 120,
    dataIndex: 'taskCycleType',
    scopedSlots: { customRender: 'taskCycleType' },
  },
  // {
  //   align: 'center',
  //   title: '描述',
  //   dataIndex: 'description',
  //   width: 220,
  //   scopedSlots: { customRender: 'description' },
  // },
  {
    align: 'center',
    title: '任务参与成员',
    dataIndex: 'participationCount',
  },
  {
    align: 'center',
    title: '已参与成员',
    dataIndex: 'participatedCount',
  },
  // {
  //   align: 'center',
  //   title: '品牌车系',
  //   width: 180,
  //   scopedSlots: { customRender: 'principalName' },
  // },

  {
    align: 'center',
    title: '任务状态',
    width: 120,
    scopedSlots: { customRender: 'taskStatusColumn' },
  },
  {
    align: 'center',
    title: '任务时间',
    customRender: (text) => {
      return (
        `${text.startTime ? text.startTime.split(' ')[0] : '-'} 至 ${
          text.endTime ? text.endTime.split(' ')[0] : '-'
        }` || '-'
      );
    },
  },
  {
    align: 'center',
    title: '操作',
    scopedSlots: { customRender: 'operation' },
  },
];

const checkInteger = (rule, value, callback) => {
  const isInter = new RegExp(/^-?[0-9]\d*$/).test(value);
  if (!isInter || value < 1 || value > 100) {
    return callback(new Error('数值需为1~100的整数'));
  } else {
    return callback();
  }
};
export const rules = {
  taskName: [{ required: true, message: '任务名称不能为空', trigger: 'blur' }],
  num: [
    { required: true, message: '请填写拍摄数量', trigger: 'blur' },
    { validator: checkInteger, trigger: 'blur' },
  ],
  range: [{ required: true, message: '请选择有效时间', trigger: 'change' }],
  principalId: [{ required: true, message: '请选择品牌', trigger: 'change' }],
  carSeriesId: [{ required: true, message: '请选择车系', trigger: 'change' }],
  memberIdList: [{ required: true, message: '请选择参与成员', trigger: 'change' }],
  numFieldArr: [{ required: true, message: '请选择数值类型', trigger: 'change' }],
};

export const examineColumns = [
  {
    align: 'center',
    title: '成员名称',
    dataIndex: 'memberName',
    customRender: (text) => {
      return text || '-';
    },
  },
  {
    align: 'center',
    title: '审核状态',
    scopedSlots: { customRender: 'examineStatus' },
  },
  {
    align: 'center',
    title: '提交时间',
    dataIndex: 'latestAtime',
    customRender: (text) => {
      return text || '-';
    },
  },
  {
    align: 'center',
    title: '提交人',
    dataIndex: 'latestActor',
    customRender: (text) => {
      return text || '-';
    },
  },
  {
    align: 'center',
    title: '操作',
    scopedSlots: { customRender: 'operation' },
  },
];

export const TASK_TYPE_MAP = {
  PHOTOS: '拍摄图片',
  VIDEOS: '拍摄视频',
  NUM: '收集数据',
};

export const REPEAT_TYPE_MAP = {
  NOT_REPEAT: '不重复',
  WORK_DAY_REPEAT: '每工作日重复',
};
