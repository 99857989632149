<template>
  <a-select v-model="inputValue" @change="handleChange" v-bind="$attrs" v-on="$listeners" @search="getSeriesList">
    <a-spin v-if="loading" slot="notFoundContent" size="small" />
    <a-select-option v-else v-for="item in seriesList" :key="`${item.id},${item.name}`">{{
      item.name
    }}</a-select-option>
  </a-select>
</template>

<script>
import api from '@/api/xhsAgencyApi';
import debounce from 'lodash/debounce';

export default {
  props: {
    value: {
      type: [String, Array],
    },
    principalIds: {
      type: Array,
      default: () => [],
    },
    allEmptySearch: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    this.getSeriesList = debounce(this.getSeriesList, 500);
    return {
      loading: false,
      inputValue: undefined,
      seriesList: [],
    };
  },
  watch: {
    value(val) {
      this.inputValue = val;
    },
    principalIds: {
      handler() {
        this.getSeriesList();
      },
      deep: true,
    },
  },
  methods: {
    async getSeriesList(filter) {
      filter = filter ? filter.trim() : '';
      // 是否存在品牌
      const params = {};
      this.seriesList = [];
      if (this.principalIds && this.principalIds.length) {
        const principalIds =
          this.principalIds.reduce((prev, item) => {
            prev += `,${item}`;
            return prev;
          }) || '';
        params.principalIds = principalIds;
        // 是否筛选车系
        if (filter) {
          // 筛选
          params.filter = filter;
        }
      } else {
        // 不选择品牌，搜索全部车系
        if (!this.allEmptySearch && !filter) return;
        this.seriesList = [];
        // 是否筛选车系
        if (filter) {
          // 筛选
          params.filter = filter;
        } else {
          // 不筛选车系
          this.seriesList = [];
          return;
        }
      }
      this.loading = true;
      try {
        const { code, data, message } = await api.getNewTypeList(params);
        this.loading = false;
        if (code === 200) {
          this.seriesList = data;
        } else {
          return this.$message.error(`${message}`);
        }
      } catch (error) {
        this.loading = false;
        this.$message.error('操作失败');
      }
    },
    handleChange(val) {
      this.$emit('input', val);
      this.$emit('change', val); // 避免使用@change 父组件会触发两次
      this.$emit('onChange', val);
    },
  },
  created() {
    this.inputValue = this.value;
    this.getSeriesList();
  },
};
</script>

<style scoped lang="scss"></style>
