const storage = {
  check() {
    if (window.Storage && window.localStorage && window.localStorage instanceof window.Storage) {
      window.localStorage.setItem("__checked", "useful")
      if (window.localStorage.getItem("__checked") === "useful") {
        window.localStorage.removeItem("__checked")
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  },
  set(name, data) {
    let str = JSON.stringify(data)
    window.localStorage.setItem(name, str)
  },
  get(name) {
    let str = window.localStorage.getItem(name)
    if (str) {
      return JSON.parse(str)
    } else {
      return null
    }
  },
  remove(name) {
    window.localStorage.removeItem(name)
  }
}

// [
//   {
//     name:"video/2021-3-30/Volkswagen id4 interior  Volkswagen id4x.mp4",
//     checkpoint: {"file":{"uid":"vc-upload-1617095636592-15"},"name":"video/2021-3-30/Volkswagen id4 interior  Volkswagen id4x.mp4","fileSize":72703818,"partSize":1048576,"uploadId":"D5259FA2173F45A488FDD35BE906B535","doneParts":[{"number":2,"etag":"\"DDC59CDFC38AFEE31E068901C1E3D626\""},{"number":1,"etag":"\"DA696EDACA81185B77D469F5CB2D0F05\""},{"number":4,"etag":"\"F37206D7E55364A47175B3EAD14A96A8\""},{"number":6,"etag":"\"5E984982FEFBE85AAB1A00FCD3F357FF\""},{"number":3,"etag":"\"22B07F4A4C70AAFA892BAE3ED4D39DE6\""},{"number":8,"etag":"\"41763D5F2ED71589D1D2787F0E353C94\""},]}
//   }
// ]
export const name = "record_list"

export default function generateRecorder(fileName, buz_id = '') {
  let recordName = fileName + buz_id
  function record(checkpoint) {
    // 支持多个record同时记录
    let list = storage.get(name)
    let record = {
      name: recordName,
    }
    if (list) {
      let i = -1
      for (let idx = 0; idx < list.length; idx++) {
        if (list[idx].name === recordName) {
          i = idx;
          break
        }
      }
      if (i <= -1) {
        record.checkpoint = checkpoint
        list.push(record)
        storage.set(name, list)
      } else {
        list[i].checkpoint = checkpoint
        storage.set(name, list)
      }
    } else {
      list = []
      record.checkpoint = checkpoint
      list.push(record)
      storage.set(name, list)
    }
  }
  function removeRecord() {
    let list = storage.get(name)
    if (list) {
      let i = -1
      for (let idx = 0; idx < list.length; idx++) {
        if (list[idx].name === recordName) {
          i = idx;
          break
        }
      }
      if (i <= -1) {
        return false
      } else {
        list.splice(i, 1)
        storage.set(name, list)
        return true
      }
    } else {
      return false
    }
  }
  return [record, removeRecord]
}
