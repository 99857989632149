<template>
  <div class="mission-list">
    <div style="display: flex; justify-content: space-between; margin-bottom: 10px">
      <a-space size="large">
        <!-- <BrandSelectInput
          style="width: 220px"
          placeholder="品牌"
          v-model="searchForm.principalIdListData"
          show-search
          option-filter-prop="children"
          allowClear
          @onChange="handleBrandChange"
        />
        <CarSeriesSelectInput
          style="width: 220px"
          :principalIds="principalIds"
          v-model="searchForm.carSeriesIdListData"
          placeholder="车系"
          show-search
          option-filter-prop="children"
          allowClear
          @onChange="getDataList(true)"
        /> -->
        <a-range-picker
          v-model="searchForm.filterTime"
          :placeholder="['开始日期', '结束日期']"
          format="YYYY-MM-DD"
          value-format="YYYY-MM-DD"
          style="width: 220px"
          @change="getDataList(true)"
        />
        <a-input-search
          style="width: 220px"
          placeholder="任务名称"
          v-model.trim="searchForm.taskName"
          @search="getDataList(true)"
        ></a-input-search>
      </a-space>
      <a-button type="primary" icon="plus" @click="handleUpdateOpen()">创建任务</a-button>
    </div>
    <a-table
      :loading="tableLoading"
      :columns="columns"
      :data-source="dataList"
      :rowKey="(record) => record.id"
      :pagination="false"
    >
      <div class="description_text" slot="description" slot-scope="text">
        {{ text }}
      </div>
      <div slot="taskCycleType" slot-scope="text">
        {{ text == 'WORK_DAY_REPEAT' ? '每工作日重复' : text == 'NOT_REPEAT' ? '不重复' : '-' }}
      </div>
      <div slot="principalName" slot-scope="record">
        <div>{{ record.principalName || '-' }}</div>
        <div>{{ record.carSeriesName || '-' }}</div>
      </div>
      <div slot="taskStatusColumn" slot-scope="record">
        <div :class="['status-item', record.status]" v-if="record.status === 'IN_PROGRESS'">进行中</div>
        <div :class="['status-item', record.status]" v-else-if="record.status === 'NOT_STARTED'">未开始</div>
        <div :class="['status-item', record.status]" v-else-if="record.status === 'EXPIRED'">已结束</div>
        <div v-else>-</div>
      </div>
      <div slot="operation" slot-scope="record">
        <a-space>
          <a-button type="link" size="small" @click="handleCheckDetail(record)">查看</a-button>
          <a-button v-if="record.taskType !== 'NUM'" type="link" size="small" @click="handleExamine(record.id)">
            审核
          </a-button>
          <a-button v-show="record.status !== 'EXPIRED'" type="link" size="small" @click="handleEdit(record.id)">
            编辑
          </a-button>
          <a-popconfirm title="确定删除该任务？" ok-text="确定" cancel-text="取消" @confirm="handleDel(record.id)">
            <a-button type="link" size="small">删除</a-button>
          </a-popconfirm>
        </a-space>
      </div>
    </a-table>
    <base-pagination
      :currentPage="pagination.current"
      :options="['10', '30', '50']"
      :pageSize="pagination.pageSize"
      :total="pagination.total"
      @onChange="handlePaginationChange"
      @onShowSizeChange="handlePaginationChange"
    />

    <UpdateModalComp ref="UpdateModalComp" @refresh="getDataList(true)" />
    <DetailModalComp ref="DetailModalComp" />
  </div>
</template>

<script>
import UpdateModalComp from './components/updateModalComp.vue';
import DetailModalComp from './components/detailModalComp.vue';
import { columns } from './data';
import api from '@/api/xhsAgencyApi';

export default {
  name: 'missionList',
  props: {},
  components: { UpdateModalComp, DetailModalComp },
  data() {
    return {
      columns,
      searchForm: {
        principalIdListData: [],
        carSeriesIdListData: [],
        filterTime: undefined,
        taskName: undefined,
      },
      pagination: {
        current: 1,
        pageSize: 10,
        total: 1,
        totalCitationCount: 0,
      },
      dataList: [],
      tableLoading: false,
    };
  },
  computed: {
    principalIds() {
      return this.searchForm.principalIdListData?.length > 0 ? [this.searchForm.principalIdListData.split(',')[0]] : [];
    },
  },
  watch: {},
  created() {
    this.getDataList();
  },
  mounted() {},
  methods: {
    async getDataList(isFirst) {
      if (isFirst) this.pagination.current = 1;
      const params = {
        page: this.pagination.current,
        size: this.pagination.pageSize,
        principalId:
          this.searchForm.principalIdListData?.length > 0
            ? this.searchForm.principalIdListData.split(',')[0]
            : undefined,
        carSeriesId:
          this.searchForm.carSeriesIdListData?.length > 0
            ? this.searchForm.carSeriesIdListData.split(',')[0]
            : undefined,
        startTime:
          this.searchForm.filterTime && this.searchForm.filterTime[0]
            ? this.searchForm.filterTime[0] + ' 00:00:00'
            : undefined,
        endTime:
          this.searchForm.filterTime && this.searchForm.filterTime[1]
            ? this.searchForm.filterTime[1] + ' 23:59:59'
            : undefined,
        taskName: this.searchForm.taskName,
      };
      this.tableLoading = true;
      const { code, data, message } = await api.getTaskList(params).finally(() => (this.tableLoading = false));
      if (code === 200) {
        this.dataList = data.list;
        this.pagination.total = data.total;
      } else {
        this.$message.error(message);
      }
    },
    handlePaginationChange(current, pageSize) {
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.getDataList();
    },
    handleBrandChange() {
      this.searchForm.carSeriesIdListData = undefined;
      this.getDataList(true);
    },
    handleUpdateOpen() {
      this.$refs.UpdateModalComp.handleOpen();
    },
    handleCheckDetail(record) {
      if (record.taskType === 'NUM') {
        this.$router.push({ path: '/xhsAgency/dataCollectionDetail', query: { id: record.id } });
      } else {
        this.$refs.DetailModalComp.handleOpen(record.id);
      }
    },
    handleExamine(id) {
      this.$router.push({ name: 'missionExamine', query: { id } });
    },
    handleEdit(id) {
      this.$refs.UpdateModalComp.handleOpen(id);
    },
    async handleDel(id) {
      const { code, message } = await api.handleDelTask(id);
      if (code === 200) {
        if (this.dataList.length === 1 && this.pagination.current > 1) {
          this.pagination.current--;
        }
        this.$message.success('删除成功');
        this.getDataList();
      } else {
        this.$message.error(message);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.status-item {
  margin: 0 auto;
  max-width: 60px;
  height: 25px;
  line-height: 25px;
  border-radius: 8px;
  color: #fff;
  user-select: none;
  text-align: center;

  &.IN_PROGRESS {
    background: #02f08c;
  }
  &.NOT_STARTED {
    background: #e23f53;
  }
  &.EXPIRED {
    background: #bfbfbf;
  }
}

.form-title {
  font-weight: bolder;
  margin: 10px 0 5px 0;
}

/deep/.ant-form-item {
  margin-bottom: 9px;
}

.description_text {
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:hover {
    white-space: normal;
    overflow: visible;
  }
}
</style>
